import React from "react";
import {useParams} from 'react-router-dom'
import {RequestDetailHeader} from "./RequestDetailHeader";
import {RequestDetailOverview} from "./RequestDetailOverview";

export function RequestDetail(props) {
    const params = useParams();
    return (
        <>
            <RequestDetailHeader traceId={params.traceId} structuredLogsService={props.structuredLogsService}/>
            <br/>
            <RequestDetailOverview traceId={params.traceId} structuredLogsService={props.structuredLogsService}/>
        </>
    );
}

