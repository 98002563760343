export function mapInventoryLocation(tenant : string): string {
    switch(tenant) {
        case "1060": {
            return "Blue Ribbon"
        }
        case "1063": {
            return "Samples"
        }
        case "1064": {
            return "Wings"
        }
        case "1065": {
            return "Cortez"
        }
        case "1067": {
            return "Windrunner"
        }
        case "1264": {
            return "Court"
        }
        default: {
            return tenant
        }
    }
}
