import React from 'react'
import {TextField} from "@nike/eds";

export const GlobalFilter = ({ filter, setFilter}) => {

    return (
    <TextField
        type="text" // default type
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        id="id"
        hasErrors={false}
        hideLabel={true}
        placeholder="Filter"
        style={{width: 300, margin: 20}}
    />
    )
}
